import React from 'react';
import { Spin } from 'antd';
import { CoreExtensionSlotParams } from './type';

const LazySessions = React.lazy(() => import('./Sessions'));
const LazyManageUserSessions = React.lazy(() => import('./ManageUserSessions'));

export const AsyncSessions: React.FC<CoreExtensionSlotParams<any>> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazySessions {...props} />
	</React.Suspense>
);

export const AsyncManageUserSessions: React.FC<CoreExtensionSlotParams<any>> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyManageUserSessions {...props} />
	</React.Suspense>
);
